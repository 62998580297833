<script>
import service from '@/api/service'
import { Bar, Line as LineChart } from 'vue-chartjs/legacy'

const apiName = '/api/proyecciones-financieras'
export default {
  name: 'ProyeccionesFinancierasMainView',
  components: { Bar, LineChart },
  props: {
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 40
    },
    height: {
      type: Number,
      default: 40
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      inpc_historico: [],
      inpc_proyeccion: [],
      inpc_anterior: [],
      chartHistoricoId: 'chart-historico-id',
      chartAnteriorId: 'chart-anterior-id',
      chartActualId: 'chart-actual-id',
      chartProyeccionId: 'chart-proyeccion-id',
      chartProyeccionData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'Proyeccion',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }, {
          label: 'Optimista',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgba(255, 205, 86)',
          borderWidth: 1
        }, {
          label: 'Pesimista',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132)',
          borderWidth: 1
        }]
      },
      chartINPCHistoricoData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'INPC',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }]
      },
      chartINPCAnteriorData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'INPC',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }]
      },
      chartINPCProyeccionData: {
        labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        datasets: [{
          label: 'INPC',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgb(54, 162, 235)',
          borderWidth: 1
        }, {
          label: 'Pronóstico',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192)',
          borderWidth: 1
        }, {
          label: 'Optimista',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 205, 86, 0.2)',
          borderColor: 'rgba(255, 205, 86)',
          borderWidth: 1
        }, {
          label: 'Pesimista',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132)',
          borderWidth: 1
        }]
      },
      chartPagosOptions: {
        responsive: true,
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              return tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
            }
          }
        },
        scales: {
          y: {
            beginAtZero: false
          }
        }
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      this.loading = true
      this.getHistorico()
      this.getAnterior()
      this.getProyeccion()
      this.getProyeccionFinanciera()
      this.loading = false
    },
    async getHistorico () {
      const result = await service.get(`${apiName}/inpc-historico`)
      this.inpc_historico = result.data
      const labels = result.data.data.data.map((row) => row[0])
      const chartData = result.data.data.data.map((row) => row[1])
      this.chartINPCHistoricoData.labels = labels
      this.chartINPCHistoricoData.datasets[0].data = chartData
    },
    async getAnterior () {
      const result = await service.get(`${apiName}/inpc-anterior`)
      this.inpc_anterior = result.data
      const chartData = result.data.data.data.map((row) => row[1])
      this.chartINPCAnteriorData.datasets[0].data = chartData
    },
    async getProyeccion () {
      const result = await service.get(`${apiName}/inpc-proyeccion`)
      this.inpc_proyeccion = result.data
      const chartData = result.data.data.data.map((row) => row[1])
      const chartData2 = result.data.data.forecast.map((row) => row[1])
      const chartData3 = result.data.data.forecast.map((row) => row[2])
      const chartData4 = result.data.data.forecast.map((row) => row[3])
      this.chartINPCProyeccionData.datasets[0].data = chartData
      this.chartINPCProyeccionData.datasets[1].data = chartData2
      this.chartINPCProyeccionData.datasets[3].data = chartData4
      this.chartINPCProyeccionData.datasets[2].data = chartData3
    },
    async getProyeccionFinanciera () {
      const result = await service.get(`${apiName}/proyeccion-financiera`)
      const chartData1 = result.data.data.map((row) => row[1])
      const chartData2 = result.data.data.map((row) => row[2])
      const chartData3 = result.data.data.map((row) => row[3])
      this.chartProyeccionData.datasets[0].data = chartData1
      this.chartProyeccionData.datasets[1].data = chartData2
      this.chartProyeccionData.datasets[2].data = chartData3
    }
  }
}
</script>

<template>
  <base-view title="Proyecciones Financieras" icon="trending-up">
    <div>
      <check-authorization :requiresAuthorizations="['indice proyecciones']">
        <div class="row">
          <div class="col-xxl-3">
            <div class="card mb-4">
              <div class="card-header border-bottom">
                Comportamiento Histórico del INPC
              </div>
              <div class="card-body">
                <Bar
                  :chart-options="chartPagosOptions"
                  :chart-data="chartINPCHistoricoData"
                  :chart-id="chartHistoricoId"
                  :dataset-id-key="datasetIdKey"
                  :plugins="plugins"
                  :css-classes="cssClasses"
                  :styles="styles"
                  :width="width"
                  :height="height"
                  v-if="!loading"
                />
                <loading v-else></loading>
              </div>
            </div>
          </div>
          <div class="col-xxl-3">
            <div class="card mb-4">
              <div class="card-header border-bottom">
                INPC Año anterior
              </div>
              <div class="card-body">
                <LineChart
                  :chart-options="chartPagosOptions"
                  :chart-data="chartINPCAnteriorData"
                  :chart-id="chartAnteriorId"
                  :dataset-id-key="datasetIdKey"
                  :plugins="plugins"
                  :css-classes="cssClasses"
                  :styles="styles"
                  :width="width"
                  :height="height"
                  v-if="!loading"
                  :options="options"
                />
                <loading v-else></loading>
              </div>
            </div>
          </div>
          <div class="col-xxl-3">
            <div class="card mb-4">
              <div class="card-header border-bottom">
                INPC Año actual, proyección
              </div>
              <div class="card-body">
                <LineChart
                  :chart-options="chartPagosOptions"
                  :chart-data="chartINPCProyeccionData"
                  :chart-id="chartActualId"
                  :dataset-id-key="datasetIdKey"
                  :plugins="plugins"
                  :css-classes="cssClasses"
                  :styles="styles"
                  :width="width"
                  :height="height"
                  v-if="!loading"
                />
                <loading v-else></loading>
              </div>
            </div>
          </div>
          <div class="col-xxl-3">
            <div class="card mb-4">
              <div class="card-header border-bottom">
                Proyección Financiera
              </div>
              <div class="card-body">
                <LineChart
                  :chart-options="chartPagosOptions"
                  :chart-data="chartProyeccionData"
                  :chart-id="chartProyeccionId"
                  :dataset-id-key="datasetIdKey"
                  :plugins="plugins"
                  :css-classes="cssClasses"
                  :styles="styles"
                  :width="width"
                  :height="height"
                  v-if="!loading"
                />
                <loading v-else></loading>
              </div>
            </div>
          </div>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<style scoped>

</style>
